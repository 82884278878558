<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <user-edit-tab-account
      :user-data="userData"
      :lista-empresas="listaEmpresas"
      class="mt-0 pt-0"
    />
  </component>
</template>

<script>
import {
  BAlert, BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
    const userData = ref({})

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const listaEmpresas = ref([])

    store.dispatch('app-user/fetchEmpresas')
      .then(response => {
        listaEmpresas.value = response.data
      })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
        userData.value.permissoes = userData.value.ability.map(perm => `${perm.action} ${perm.subject}`)
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      listaEmpresas,
    }
  },
}
</script>
